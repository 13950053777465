  <template>
  <div
      v-if="isOpen"
      class="w-full h-full bg-black bg-opacity-70 top-0 fixed sticky-0 z-40"
      :id="`collapse${_uid}`"
      @click="isOpen = false"
    >
      <div class="max-w-xl mt-0 md:mt-12 mx-auto my-auto p-2 bg-white shadow-xl z-50" @click="isOpen = true">
        <div class="flex items-center justify-between" @click="isOpen = true">
          <slot name="title"/>

          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div class="mt-4" @click="isOpen = true">
          <slot name="image"/>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Accreditation',
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleAccreditation() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
