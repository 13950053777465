<template>
  <div id="mobile-menu" class="bg-navy z-30 p-4 text-white min-h-full flex justify-between">
    <div v-show="!isOpen">
      <slot name="left"/>
    </div>
    <div v-show="isOpen" :class="{fixed: isOpen}" class="cursor-pointer z-40">
      <slot name="logo"/>
    </div>

    <div :class="{fixed: isOpen}" class="cursor-pointer z-50 absolute top-0 right-0 p-4 mobile-nav-open"
         @click="toggleNavigation()">
      <svg v-show="!isOpen" xmlns="http://www.w3.org/2000/svg" width="29.309" height="20" viewBox="0 0 29.309 20">
        <g id="Group_738" data-name="Group 738" transform="translate(1 1)">
          <line id="Line_20" data-name="Line 20" x2="27.309" fill="none" stroke="#fff" stroke-linecap="round"
                stroke-width="2"/>
          <path id="Path_850" data-name="Path 850" d="M0,0H19" transform="translate(8.309 18)" fill="none" stroke="#fff"
                stroke-linecap="round" stroke-width="2"/>
          <path id="Path_852" data-name="Path 852" d="M0,0H22.419" transform="translate(4.89 9)" fill="none"
                stroke="#fff" stroke-linecap="round" stroke-width="2"/>
        </g>
      </svg>
      <svg v-show="isOpen" xmlns="http://www.w3.org/2000/svg" width="27.341" height="27.341"
           viewBox="0 0 27.341 27.341" class="fixed top-0 right-0 mt-4 mr-4">
        <g transform="translate(-321.586 -30.086)">
          <line x2="24.512" y2="24.512" transform="translate(323 31.5)" fill="none" stroke="#fff"
                stroke-linecap="round" stroke-width="2"/>
          <line y1="24.512" x2="24.512" transform="translate(323 31.5)" fill="none" stroke="#fff"
                stroke-linecap="round" stroke-width="2"/>
        </g>
      </svg>
    </div>

    <transition
      enter-class="translate-x-full"
      enter-active-class="ease-out transition-slow"
      enter-to-class="translate-x-0"
      leave-class="translate-x-0"
      leave-active-class="ease-in transition-medium"
      leave-to-class="translate-x-full"
      appear
    >
      <div class="fixed bg-navy z-30 w-full h-full left-0 top-0 bg-navy py-10 px-4" v-if="isOpen">

        <div class="flex justify-start primary-menu pt-12" v-if="!activeDivision">
          <a v-for="item in primaryMenu"
             v-bind:key="'primary_' + item.id"
             :href="item.url"
             :target="item.target"
             :title="item.title"
             v-animate="'slide-in-left'"
             class=""
          >
            {{ item.title }}
          </a>
        </div>
        <transition
          enter-class="translate-x-full"
          enter-active-class="ease-out transition-slow"
          enter-to-class="translate-x-0"
          leave-class="translate-x-0"
          leave-active-class="ease-in"
          leave-to-class="translate-x-full"
          appear
        >
          <div class="flex justify-start primary-menu pt-12" v-if="activeDivision">
            <a @click="activeDivision = null" class="flex justify-start">
              <svg class="mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" width="11.838" height="16.392"
                   viewBox="0 0 11.838 16.392">
                <g id="Group_642" data-name="Group 642" transform="translate(10.79 1.048) rotate(90)">
                  <line id="Line_32" data-name="Line 32" x2="9.741" y2="7.148"
                        transform="translate(14.295 0) rotate(90)"
                        fill="none" stroke="#87d5ca" stroke-linecap="round" stroke-width="1.5"/>
                  <line id="Line_33" data-name="Line 33" y1="7.148" x2="9.741" transform="translate(7.148 0) rotate(90)"
                        fill="none" stroke="#87d5ca" stroke-linecap="round" stroke-width="1.5"/>
                </g>
              </svg>
              Back
            </a>
          </div>
        </transition>

        <div class="mt-12" v-if="!activeDivision">
          <span class="division-text">Our Divisions</span>
          <ul class="list-group mt-4">
            <li v-for="(item, index) in divisionMenu"
                v-bind:key="'division_primary_' + item.id"
                v-if="item.parent === 0"
                class="font-semibold uppercase text-lg cursor-pointer py-3 list-group-item"
            >
              <a :href="item.url"
                 :target="item.target"
                 :title="item.title"
                 :class="(item.division_class ? 'border-0 border-l-4 pl-4 border-' + item.division_class : '')"
                 @click.prevent="selectDivision(index)"
              >
                {{ item.title }}

                <div class="text-white float-right"
                     v-if="Object.keys(item.children).length > 0"
                     @click.prevent="selectDivision(index)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" viewBox="0 0 16 25"
                       v-show="item !== activeDivision">
                    <text id="_" data-name="+" fill="#FFFFFF" font-size="25" font-family="Helvetica">
                      <tspan x="0" y="19">+</tspan>
                    </text>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" viewBox="0 0 16 25"
                       v-show="item === activeDivision">
                    <path id="Line_114" d="M1.5,12.5l13,0"
                          style="fill:none;fill-rule:nonzero;stroke:#FFFFFF;stroke-width:3px;"/>
                  </svg>
                </div>
              </a>
            </li>
          </ul>
        </div>

        <transition
          enter-class="translate-x-full"
          enter-active-class="ease-out transition-slow"
          enter-to-class="translate-x-0"
          leave-class="translate-x-0"
          leave-active-class="ease-in"
          leave-to-class="translate-x-full"
          appear
        >
          <div v-if="activeDivision !== null" class="pt-6">
            <ul class="mt-16">
              <li v-for="item in activeDivision.children"
                  v-bind:key="'division_sub_' + item.id"
                  class="uppercase text-lg py-2"
              >
                <a :href="item.url" :target="item.target" :title="item.title">
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MobileNavigation',
  props: {
    primaryMenu: {
      type: Array,
      required: true,
    },
    divisionMenu: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      activeDivision: null,
    };
  },

  methods: {
    toggleNavigation() {
      this.isOpen = !this.isOpen;
    },
    selectDivision(index) {
      if (this.activeDivision === this.divisionMenu[index]) {
        this.activeDivision = null;
      } else if (Object.keys(this.divisionMenu[index].children).length > 0) {
        this.activeDivision = this.divisionMenu[index];
      } else {
        window.location.href = this.divisionMenu[index].url;
      }
    },
  },
};
</script>

<style lang="scss">
.ease-linear {
  transition-timing-function: linear
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.transition-fastest {
  transition-duration: 75ms
}

.transition-faster {
  transition-duration: 100ms
}

.transition-fast {
  transition-duration: 150ms
}

.transition-medium {
  transition-duration: 200ms
}

.transition-slow {
  transition-duration: 300ms
}

.transition-slower {
  transition-duration: 500ms
}

.transition-slowest {
  transition-duration: 700ms
}

.transition-very-slow {
  transition-duration: 1000ms
}

.transition-all {
  transition-property: all
}

.transition-opacity {
  transition-property: opacity
}

.transition-transform {
  transition-property: transform
}

.translate-x-full {
  transform: translateX(-100%);
}

.-translate-x-full {
  transform: translateX(100%);
}

.translate-x-0 {
  transform: translateX(0);
}

.translate-y-full {
  transform: translateY(-100%);
}

.-translate-y-full {
  transform: translateY(100%);
}

.translate-y-0 {
  transform: translateY(0);
}

.scale-90 {
  transform: scale(.9)
}

.scale-100 {
  transform: scale(1)
}

.origin-top {
  transform-origin: top
}

.origin-top-right {
  transform-origin: top right
}

.origin-right {
  transform-origin: right
}

.origin-bottom-right {
  transform-origin: bottom right
}

.origin-bottom {
  transform-origin: bottom
}

.origin-bottom-left {
  transform-origin: bottom left
}

.origin-left {
  transform-origin: left
}

.origin-top-left {
  transform-origin: top left
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
