/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import Vue from 'vue';

// External Components
import axios from 'axios';

import VueAwesomeSwiper from 'vue-awesome-swiper';

Vue.use(VueAwesomeSwiper);

// Default VueAnimate
import VueAnimate from 'vue-animate-scroll'

Vue.use(VueAnimate);

import DelayAnimate from "./DelayAnimate";

Vue.use(DelayAnimate);

import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';

Vue.use(VuePlyr);

import {extend} from 'vee-validate';
import {required, email, regex} from 'vee-validate/dist/rules';

extend('required', {
  ...required, message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate(value, args) {
    return value.length >= args.length
  }, params: ['length'], message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate(value, args) {
    return value.length <= args.length
  }, params: ['length'], message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate(value, args) {
    return value.length <= args.length
  }, params: ['length'], message: 'The {_field_} field can have only {length} of checked items'
})

extend('phone', {
  validate(value) {
    return new Promise((resolve) => {
      const phone = new PhoneNumber(value)
      resolve({valid: phone.isValid()})
    })
  }, message: 'The {_field_} field is not a valid phone number'
})

extend('password', {
  validate(value, {target}) {
    return value === target
  }, params: ['target'], message: 'Password confirmation does not match'
})

// Internal Components
const Accordion = require('./components/Accordion.vue').default;
const JobsAccordion = require('./components/JobsAccordion.vue').default;
const Accreditation = require('./components/Accreditation.vue').default;
const AppNavigation = require('./components/Navigation.vue').default;
const AppMobileNavigation = require('./components/MobileNavigation.vue').default;
const NewsSwiper = require('./components/NewsSwiper.vue').default;
const DivisionSwiper = require('./components/DivisionSwiper.vue').default;
const ProjectForm = require('./components/ProjectForm.vue').default;
const ContactForm = require('./components/ContactForm.vue').default;
const MobileSwiper = require('./components/MobileSwiper.vue').default;
const EnquiryPopupForm = require('./components/EnquiryPopupForm.vue').default;


Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.component('accordion', Accordion);
Vue.component('jobs-accordion', JobsAccordion);
Vue.component('accreditation', Accreditation);
Vue.component('app-navigation', AppNavigation);
Vue.component('app-mobile-navigation', AppMobileNavigation);
Vue.component('news-swiper', NewsSwiper);
Vue.component('division-swiper', DivisionSwiper);
Vue.component('project-form', ProjectForm);
Vue.component('contact-form', ContactForm);
Vue.component('mobile-swiper', MobileSwiper);
Vue.component('enquiry-form-popup', EnquiryPopupForm);

const app = new Vue({
  el: '#app', data() {
    return {}
  },
});

window.addEventListener('load', (event) => {
  const fadeElement = document.querySelector('div#app.faded');
  if (fadeElement) {
    fadeElement.classList.remove('faded');
  }

  let anchorLinks = document.querySelectorAll('a[href^="#"]')

  for (let item of anchorLinks) {
    item.addEventListener('click', (e) => {
      let hashval = item.getAttribute('href')
      let target = document.querySelector(hashval)
      target.scrollIntoView({
        behavior: 'smooth', block: 'start'
      });
      history.pushState(null, null, hashval);
      e.preventDefault()
    })
  }

  if (document.body.classList.contains('single-awsm_job_openings')) {
    const form = document.querySelector('#job-form');
    let applyNowLinks = document.querySelectorAll('.job-apply-now');
    for (let jobApply of applyNowLinks) {
      jobApply.addEventListener('click', (e) => {
        e.preventDefault();
        form.classList.add('active');
      });
    }
    let closeApplyForm = document.querySelectorAll('.job-close-form');
    for (let closeForm of closeApplyForm) {
      closeForm.addEventListener('click', (e) => {
        e.preventDefault();
        form.classList.remove('active');
      });
    }
  }
});

import $ from 'jquery';

window.jQuery = $;
window.$ = $;
