var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "swiper",
          rawName: "v-swiper:news",
          value: _vm.swiperOption,
          expression: "swiperOption",
          arg: "news",
        },
      ],
    },
    [
      _c("div", { staticClass: "swiper-wrapper" }, [_vm._t("slides")], 2),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex my-4" }, [
      _c("div", { staticClass: "swiper-pagination my-auto mx-auto" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }