<template>
  <div class="absolute enquiry-popup-form" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-show="isOpen">
    <div class="fixed inset-0 bg-navy"></div>

    <div class="fixed top-0 left-0 pt-4 pl-4 text-white z-50">
      <svg xmlns="http://www.w3.org/2000/svg" width="27.341" height="27.341" viewBox="0 0 27.341 27.341"
           @click="isOpen = false">
        <g transform="translate(-321.586 -30.086)">
          <line x2="24.512" y2="24.512" transform="translate(323 31.5)" fill="none" stroke="#fff"
                stroke-linecap="round" stroke-width="2"/>
          <line y1="24.512" x2="24.512" transform="translate(323 31.5)" fill="none" stroke="#fff"
                stroke-linecap="round" stroke-width="2"/>
        </g>
      </svg>
    </div>

    <div class="fixed z-10 inset-0 overflow-y-auto text-white">
      <div class="fixed md:my-auto max-w-screen-lg w-full z-0 select-none">
        <slot name="overlays" />
      </div>
      <div class="flex items-end justify-center min-h-full p-4 sm:p-0 z-30">
        <div
          class="relative px-4 pt-5 pb-4 transform md:my-auto max-w-screen-lg w-full sm:p-6">
          <div class="mt-3">

            <div v-if="success" class="text-center">
                <p class="heading">Thank You!</p>
                <p class="mt-4">A member of the Cross team will be in touch.</p>
            </div>

            <div class="mt-2" v-if="!success">
              <p class="heading">
                <slot name="title"/>
              </p>
              <p>Please fill out this short form and a member of the Cross team will be in touch.</p>

              <div v-if="error" class="text-red-600 pt-6" role="alert">
                <p><strong>Error:</strong> {{ error }}</p>
              </div>

              <validation-observer ref="form" v-slot="{ passes, invalid }" id="icon-enquiry-popup-form" tag="div">
              <form @submit.prevent="sendMessage">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-8 md:gap-y-4">
                  <validation-provider v-slot="{ errors }" name="Name" rules="required" tag="div">
                    <label for="form-name" class="label required sr-only">Full name</label>
                    <input
                      id="form-name"
                      v-model="form.name"
                      :class="{ error: errors[0] }"
                      type="text"
                      placeholder="Full Name"
                    />
                    <div v-show="errors" class="error">{{ errors[0] }}</div>
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" name="Company" rules="required" tag="div">
                    <label for="form-name" class="label required sr-only">Company</label>
                    <input
                      id="form-company"
                      v-model="form.company"
                      :class="{ error: errors[0] }"
                      type="text"
                      placeholder="Company"
                    />
                    <div v-show="errors" class="error">{{ errors[0] }}</div>
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" name="Email Address" rules="required|email" tag="div">
                    <label for="form-email" class="label required sr-only">Email address</label>
                    <input
                      id="form-email"
                      v-model="form.email"
                      :class="{ error: errors[0] }"
                      type="text"
                      class="form-input input"
                      placeholder="Email"
                    />
                    <div v-show="errors" class="error">{{ errors[0] }}</div>
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" name="Phone Number" rules="required" tag="div">
                    <label for="form-email" class="label required sr-only">Phone Number</label>
                    <input
                      id="form-phone"
                      v-model="form.phone"
                      :class="{ error: errors[0] }"
                      type="text"
                      class="form-input input"
                      placeholder="Phone"
                    />
                    <div v-show="errors" class="error">{{ errors[0] }}</div>
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" name="Project Outline" rules="required" class="md:col-span-2" tag="div">
                    <label for="form-message" class="label required sr-only">Project Outline</label>
                    <textarea
                      id="form-message"
                      v-model="form.message"
                      :class="{ error: errors[0] }"
                      class="form-textarea textarea"
                      rows="2"
                      placeholder="Project outline..."
                    ></textarea>
                    <div v-show="errors" class="error">{{ errors[0] }}</div>
                  </validation-provider>

                  <div class="md:col-span-2">
                    <button type="button" class="button bg-brand" :disabled="loading" @click="passes(sendMessage)">
                      {{ !loading ? 'Send' : 'Sending... ' }}
                    </button>
                  </div>
                </div>
              </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import axios from "axios";

export default {
  // name: 'EnquiryPopupForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      success: false,
      error: false,
      form: {
        name: null,
        company: null,
        phone: null,
        email: null,
        message: null,
      }
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    sendMessage() {
      this.loading = true;
      let self = this;

      this.$refs.form.validate().then(function (valid) {
        if (valid) {
          axios.post('/wp-json/icon/v1/enquiry-popup', self.form)
            .then((response) => {
              // Clear input fields.
              self.form.email = null
              self.form.name = null;
              self.form.company = null;
              self.form.phone = null;
              self.form.message = null;
              self.error = false;

              self.success = response.data.message;
            })
            .catch((err) => {
              self.success = false;
              self.error = err.response.data.message;
            })
            .then(() => {
              self.loading = false;
            })
        }
      });

    }
  },
};
</script>
