var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bg-navy fixed z-40 text-white h-full",
      attrs: { id: "main-menu" },
    },
    [
      _c(
        "div",
        {
          staticClass: "bg-navy fixed cursor-pointer h-full p-4",
          staticStyle: { width: "60px" },
          on: { click: _vm.toggleNavigation },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isOpen,
                  expression: "!isOpen",
                },
              ],
            },
            [_vm._t("icon")],
            2
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen,
                  expression: "isOpen",
                },
              ],
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "27.341",
                height: "27.341",
                viewBox: "0 0 27.341 27.341",
              },
            },
            [
              _c("g", { attrs: { transform: "translate(-321.586 -30.086)" } }, [
                _c("line", {
                  attrs: {
                    x2: "24.512",
                    y2: "24.512",
                    transform: "translate(323 31.5)",
                    fill: "none",
                    stroke: "#fff",
                    "stroke-linecap": "round",
                    "stroke-width": "2",
                  },
                }),
                _vm._v(" "),
                _c("line", {
                  attrs: {
                    y1: "24.512",
                    x2: "24.512",
                    transform: "translate(323 31.5)",
                    fill: "none",
                    stroke: "#fff",
                    "stroke-linecap": "round",
                    "stroke-width": "2",
                  },
                }),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "bg-navy p-4 z-50 h-full pt-12 desktop-nav",
          class: { "desktop-nav-open": _vm.isOpen },
        },
        [
          _c(
            "div",
            { staticClass: "max-w-screen-xl mx-auto ipad-padding-sm" },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  [_vm._t("logo")],
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ml-12 flex justify-center" },
                    _vm._l(_vm.primaryMenu, function (item) {
                      return _c(
                        "a",
                        {
                          key: "primary_" + item.id,
                          staticClass: "pr-12 my-auto uppercase text-lg",
                          attrs: {
                            href: item.url,
                            target: item.target,
                            title: item.title,
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-4/5 mt-24" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-turquoise text-sm font-semibold uppercase",
                  },
                  [_vm._v("Our Divisions")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex mt-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "divisions-nav flex flex-col pr-12 border-0 border-r border-purple bg-navy z-50",
                    },
                    [
                      _c(
                        "ul",
                        _vm._l(_vm.divisionMenu, function (item, index) {
                          return item.parent === 0
                            ? _c(
                                "li",
                                {
                                  key: "division_primary_" + item.id,
                                  staticClass:
                                    "font-semibold uppercase text-lg cursor-pointer py-3",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      class: item.division_class
                                        ? "border-0 border-l-4 pl-4 border-" +
                                          item.division_class
                                        : "",
                                      attrs: {
                                        href: item.url,
                                        target: item.target,
                                        title: item.title,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.selectDivision(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.title) +
                                          "\n\n                  "
                                      ),
                                      Object.keys(item.children).length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-white float-right",
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item !==
                                                        _vm.activeDivision,
                                                      expression:
                                                        "item !== activeDivision",
                                                    },
                                                  ],
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "16",
                                                    height: "25",
                                                    viewBox: "0 0 16 25",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "text",
                                                    {
                                                      attrs: {
                                                        id: "_",
                                                        "data-name": "+",
                                                        fill: "#FFFFFF",
                                                        "font-size": "25",
                                                        "font-family":
                                                          "Helvetica",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "tspan",
                                                        {
                                                          attrs: {
                                                            x: "0",
                                                            y: "19",
                                                          },
                                                        },
                                                        [_vm._v("+")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "svg",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item ===
                                                        _vm.activeDivision,
                                                      expression:
                                                        "item === activeDivision",
                                                    },
                                                  ],
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "16",
                                                    height: "25",
                                                    viewBox: "0 0 16 25",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    staticStyle: {
                                                      fill: "none",
                                                      "fill-rule": "nonzero",
                                                      stroke: "#FFFFFF",
                                                      "stroke-width": "3px",
                                                    },
                                                    attrs: {
                                                      id: "Line_114",
                                                      d: "M1.5,12.5l13,0",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-col pl-12 division-nav z-30",
                      class: { "division-nav-open": _vm.divisionMenuOpen },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "my-auto",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _vm.activeDivision !== null
                            ? _c(
                                "ul",
                                _vm._l(
                                  _vm.activeDivision.children,
                                  function (item) {
                                    return _c(
                                      "li",
                                      {
                                        key: "division_sub_" + item.id,
                                        staticClass: "uppercase text-lg py-2",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: item.url,
                                              target: item.target,
                                              title: item.title,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item.title) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }