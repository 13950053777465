var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "accordion-item" }, [
    _c(
      "div",
      {
        staticClass: "heading",
        attrs: {
          "aria-expanded": _vm.isOpen,
          "aria-controls": `collapse${_vm._uid}`,
        },
        on: {
          click: function ($event) {
            return _vm.toggleAccordion()
          },
        },
      },
      [
        _c(
          "span",
          { staticClass: "w-full flex justify-between" },
          [_vm._t("title")],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "toggle text-brand" }, [
          _c(
            "svg",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isOpen,
                  expression: "!isOpen",
                },
              ],
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "16",
                height: "25",
                viewBox: "0 0 16 25",
              },
            },
            [
              _c(
                "text",
                {
                  attrs: {
                    id: "_",
                    "data-name": "+",
                    fill: "currentColor",
                    "font-size": "25",
                    "font-family": "Helvetica",
                  },
                },
                [_c("tspan", { attrs: { x: "0", y: "19" } }, [_vm._v("+")])]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen,
                  expression: "isOpen",
                },
              ],
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "16",
                height: "25",
                viewBox: "0 0 16 25",
              },
            },
            [
              _c("path", {
                staticStyle: {
                  fill: "none",
                  "fill-rule": "nonzero",
                  stroke: "currentColor",
                  "stroke-width": "3px",
                },
                attrs: { id: "Line_114", d: "M1.5,12.5l13,0" },
              }),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "content",
        staticClass: "content",
        class: { expanded: _vm.isOpen },
        style: [_vm.isOpen ? { height: _vm.computedHeight } : {}],
        attrs: { id: `collapse${_vm._uid}` },
      },
      [_vm._t("content")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }