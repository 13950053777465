<template>
  <div id="main-menu" class="bg-navy fixed z-40 text-white h-full">
    <div class="bg-navy fixed cursor-pointer h-full p-4" @click="toggleNavigation"
         style="width: 60px"
    >
      <span v-show="!isOpen">
        <slot name="icon"/>
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" width="27.341" height="27.341" viewBox="0 0 27.341 27.341"
           v-show="isOpen">
        <g transform="translate(-321.586 -30.086)">
          <line x2="24.512" y2="24.512" transform="translate(323 31.5)" fill="none" stroke="#fff"
                stroke-linecap="round" stroke-width="2"/>
          <line y1="24.512" x2="24.512" transform="translate(323 31.5)" fill="none" stroke="#fff"
                stroke-linecap="round" stroke-width="2"/>
        </g>
      </svg>
    </div>

    <div class="bg-navy p-4 z-50 h-full pt-12 desktop-nav" :class="{'desktop-nav-open': isOpen}">
      <div class="max-w-screen-xl mx-auto ipad-padding-sm">
        <div class="flex">
          <template>
            <slot name="logo"/>
          </template>

          <div class="ml-12 flex justify-center">
            <a v-for="item in primaryMenu"
               v-bind:key="'primary_' + item.id"
               :href="item.url"
               :target="item.target"
               :title="item.title"
               class="pr-12 my-auto uppercase text-lg"
            >
              {{ item.title }}
            </a>
          </div>
        </div>

        <div class="w-4/5 mt-24">
          <span class="text-turquoise text-sm font-semibold uppercase">Our Divisions</span>
          <div class="flex mt-2">
            <div class="divisions-nav flex flex-col pr-12 border-0 border-r border-purple bg-navy z-50">
              <ul>
                <li v-for="(item, index) in divisionMenu"
                    v-bind:key="'division_primary_' + item.id"
                    v-if="item.parent === 0"
                    class="font-semibold uppercase text-lg cursor-pointer py-3"
                >
                  <a :href="item.url"
                     :target="item.target"
                     :title="item.title"
                     :class="(item.division_class ? 'border-0 border-l-4 pl-4 border-' + item.division_class : '')"
                     @click.prevent="selectDivision(index)"
                  >
                    {{ item.title }}

                    <div class="text-white float-right"
                         v-if="Object.keys(item.children).length > 0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" viewBox="0 0 16 25"
                           v-show="item !== activeDivision">
                        <text id="_" data-name="+" fill="#FFFFFF" font-size="25" font-family="Helvetica">
                          <tspan x="0" y="19">+</tspan>
                        </text>
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" viewBox="0 0 16 25"
                           v-show="item === activeDivision">
                        <path id="Line_114" d="M1.5,12.5l13,0"
                              style="fill:none;fill-rule:nonzero;stroke:#FFFFFF;stroke-width:3px;"/>
                      </svg>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="flex flex-col pl-12 division-nav z-30" :class="{'division-nav-open': divisionMenuOpen}">
              <div style="width: 100%" class="my-auto">
                <ul v-if="activeDivision !== null">
                  <li v-for="item in activeDivision.children"
                      v-bind:key="'division_sub_' + item.id"
                      class="uppercase text-lg py-2"
                  >
                    <a :href="item.url" :target="item.target" :title="item.title">
                      {{ item.title }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppNavigation',
  props: {
    primaryMenu: {
      type: Array,
      required: true,
    },
    divisionMenu: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      activeDivision: 0,
      divisionMenuOpen: false,
    };
  },
  computed: {
    width() {
      return this.isOpen ? '100%' : '60px';
    }
  },

  methods: {
    toggleNavigation() {
      this.isOpen = !this.isOpen;
    },
    selectDivision(index) {
      if (this.activeDivision === this.divisionMenu[index]) {
        this.divisionMenuOpen = false;

        let self = this;
        setTimeout(function() {
          self.activeDivision = null;
        }, 750);
      } else if (Object.keys(this.divisionMenu[index].children).length > 0) {
        this.activeDivision = this.divisionMenu[index];
        this.divisionMenuOpen = true;
      } else {
        window.location.href = this.divisionMenu[index].url;
      }
    }
  },
};
</script>
