<template>
  <validation-observer ref="form" v-slot="{ passes, invalid }" id="icon-contact-form" tag="div">
    <div v-if="success" class="px-4 py-3" role="alert">
      <p class="font-bold">Thank You!</p>
      <p>{{ success }}</p>
    </div>
    <div v-if="error" class="text-red-500 px-4 py-3" role="alert">
      <p>{{ error }}</p>
    </div>
    <form v-if="!success" @submit.prevent="SendMessage">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-8 md:gap-y-4">
      <validation-provider v-slot="{ errors }" name="Area" rules="required" class="md:w-4/6 md:col-span-2" tag="div">
        <label class="label required">
          Choose an area of interest:
        </label>

        <div class="areas-options mt-4">
        <label
          v-for="(area, index) in areas"
          v-bind:key="'area'+index"
          :class="{'active': form.area === area}"
          class="area">
          <input type="radio"
                 :name="'area_option'+index"
                 v-model="form.area"
                 :value="area" />

          {{ area }}
        </label>
        </div>

        <div v-show="errors" class="error clear-both">{{ errors[0] }}</div>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="Name" rules="required" tag="div">
        <label for="form-name" class="label required sr-only">Name</label>
        <input
          id="form-name"
          v-model="form.name"
          :class="{ error: errors[0] }"
          type="text"
          placeholder="Name"
        />
        <div v-show="errors" class="error">{{ errors[0] }}</div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Company" rules="required" tag="div">
        <label for="form-name" class="label required sr-only">Company</label>
        <input
          id="form-company"
          v-model="form.company"
          :class="{ error: errors[0] }"
          type="text"
          placeholder="Company"
        />
        <div v-show="errors" class="error">{{ errors[0] }}</div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Email Address" rules="required|email" tag="div">
        <label for="form-email" class="label required sr-only">Email address</label>
        <input
          id="form-email"
          v-model="form.email"
          :class="{ error: errors[0] }"
          type="text"
          class="form-input input"
          placeholder="Email"
        />
        <div v-show="errors" class="error">{{ errors[0] }}</div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Phone Number" rules="required" tag="div">
        <label for="form-email" class="label required sr-only">Email address</label>
        <input
          id="form-phone"
          v-model="form.phone"
          :class="{ error: errors[0] }"
          type="text"
          class="form-input input"
          placeholder="Phone"
        />
        <div v-show="errors" class="error">{{ errors[0] }}</div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Message" rules="required" class="md:col-span-2" tag="div">
        <label for="form-message" class="label required sr-only">How can we help?</label>
        <textarea
          id="form-message"
          v-model="form.message"
          :class="{ error: errors[0] }"
          class="form-textarea textarea"
          rows="4"
          placeholder="How can we help?"
        ></textarea>
        <div v-show="errors" class="error">{{ errors[0] }}</div>
      </validation-provider>

      <div class="md:col-span-2">
        <button type="button" :disabled="loading" @click="passes(SendMessage)">
          {{ !loading ? 'Send' : 'Sending... ' }}
        </button>
      </div>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import axios from "axios";

export default {
  name: 'ContactForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      areas: [
        'Temperature Control',
        'Ripening',
        'Air Conditioning',
        'Refrigeration',
      ],
      form: {
        name: null,
        company: null,
        phone: null,
        area: null,
        email: null,
        message: null,
      }
    }
  },
  methods: {
    SendMessage() {
      this.loading = true;
      let self = this;

      this.$refs.form.validate().then(function (valid) {
        if (valid) {
          axios.post('/wp-json/icon/v1/contact', self.form)
            .then((response) => {
              // Clear input fields.
              self.form.email = null
              self.form.name = null;
              self.form.company = null;
              self.form.phone = null;
              self.form.area = null;
              self.form.message = null;
              self.error = false;

              self.success = response.data.message;
            })
            .catch((err) => {
              self.success = false;
              self.error = err.response.data.message;
            })
            .then(() => {
              self.loading = false;
            })
        }
      });

    }
  }
}
</script>
