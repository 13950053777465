<template>
  <div class="accordion-item">
    <div
      @click="toggleAccordion()"
      class="heading"
      :aria-expanded="isOpen"
      :aria-controls="`collapse${_uid}`"
    >
      <span class="py-4 md:py-0"><slot name="title"/></span>

      <div class="pr-4 pt-3 md:pt-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" viewBox="0 0 16 25" v-show="!isOpen">
          <text id="_" data-name="+" fill="#ffaf2f" font-size="25" font-family="Helvetica">
            <tspan x="0" y="19">+</tspan>
          </text>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" viewBox="0 0 16 25" v-show="isOpen">
          <path id="Line_114" d="M1.5,12.5l13,0" style="fill:none;fill-rule:nonzero;stroke:#ffaf2f;stroke-width:3px;"/>
        </svg>
      </div>
    </div>
    <div ref="content" :id="`collapse${_uid}`"
         class="content"
         :class="{expanded: isOpen}"
         :style="[isOpen ? { height : computedHeight } : {}]">
        <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  data() {
    return {
      isOpen: false,
      computedHeight: 0,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    calculateHeight() {
      this.$refs['content'].style.height = 'auto';
      this.$refs['content'].style.visibility = 'hidden';
      this.$refs['content'].style.display = 'block';
      this.computedHeight = getComputedStyle(this.$refs['content']).height;
      this.$refs['content'].style.position = null;
      this.$refs['content'].style.visibility = null;
      this.$refs['content'].style.display = null;
      this.$refs['content'].style.height = 0;
    },
  },
  mounted: function(){
    this.calculateHeight();
  },
};
</script>
