<template>
  <div v-swiper:news="swiperOption">
    <div class="swiper-wrapper">
      <slot name="slides"/>
    </div>

    <div class="flex my-4">
      <div class="swiper-pagination my-auto mx-auto"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsSwiper',
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 60,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          // when window width is >= 640px
          640: {
            slidesPerView: 2,
          },
        }
      }

    };
  },
};
</script>
