class DelayAnimate {
    constructor(os)
    {
        this.os = os
    }

    activate(el, binding)
    {
        const value = binding.value.method;
        const delay = binding.value.delay ?? 250;

        const animateClass = this.os.animateClass
        const activeClass = this.os.activeClass

        const { top, bottom } = el.getBoundingClientRect()
        const height = document.documentElement.clientHeight
        const inWindow = top < height && bottom > 0

        setTimeout(function () {
            el.classList.add(value)
        }, delay);

        if (inWindow) {
            setTimeout(function () {
                el.classList.add(activeClass);
            }, delay);
        }
    }
}

export default {
    install(Vue, os = { animateClass: 'animate', activeClass: 'animate-active' }) {
        let a = new DelayAnimate(os)

        Vue.directive('delay-animate', {
            bind(el, binding) {
                el.classList.add(os.animateClass)
            },
            inserted(el, binding) {
                a.activate(el, binding)
                window.addEventListener('scroll', function () {
                    a.activate(el, binding)
                })
            }
        })
    }
}
