var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "div",
        {
          staticClass:
            "w-full h-full bg-black bg-opacity-70 top-0 fixed sticky-0 z-40",
          attrs: { id: `collapse${_vm._uid}` },
          on: {
            click: function ($event) {
              _vm.isOpen = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "max-w-xl mt-0 md:mt-12 mx-auto my-auto p-2 bg-white shadow-xl z-50",
              on: {
                click: function ($event) {
                  _vm.isOpen = true
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex items-center justify-between",
                  on: {
                    click: function ($event) {
                      _vm.isOpen = true
                    },
                  },
                },
                [
                  _vm._t("title"),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      staticClass: "h-6 w-6",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        stroke: "currentColor",
                        "stroke-width": "2",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          d: "M6 18L18 6M6 6l12 12",
                        },
                      }),
                    ]
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt-4",
                  on: {
                    click: function ($event) {
                      _vm.isOpen = true
                    },
                  },
                },
                [_vm._t("image")],
                2
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }