<template>
  <div v-swiper="swiperOption" class="mobile-swiper">
    <div class="swiper-wrapper">
      <slot name="slides"/>
    </div>

    <div class="flex my-8 justify-between md:justify-center">
      <div class="swiper-button-prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="59.413" height="59.413" viewBox="0 0 59.413 59.413">
          <g id="Group_702" data-name="Group 702" transform="translate(0 59.413) rotate(-90)">
            <g id="Ellipse_471" data-name="Ellipse 471" transform="translate(59.413 59.413) rotate(180)" fill="none" stroke="currentColor" stroke-width="1.5">
              <ellipse cx="29.706" cy="29.706" rx="29.706" ry="29.706" stroke="none"/>
              <ellipse cx="29.706" cy="29.706" rx="28.956" ry="28.956" fill="none"/>
            </g>
            <g id="Group_642" data-name="Group 642" transform="translate(42.298 36.754) rotate(180)">
              <line id="Line_32" data-name="Line 32" x2="17.16" y2="12.591" transform="translate(25.181) rotate(90)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
              <line id="Line_33" data-name="Line 33" y1="12.591" x2="17.16" transform="translate(12.591) rotate(90)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
            </g>
          </g>
        </svg>
      </div>

      <div class="swiper-pagination mx-0 md:mx-12"></div>

      <div class="swiper-button-next">
        <svg xmlns="http://www.w3.org/2000/svg" width="59.413" height="59.413" viewBox="0 0 59.413 59.413">
          <g id="Group_655" data-name="Group 655" transform="translate(59.413) rotate(90)">
            <g id="Ellipse_471" data-name="Ellipse 471" transform="translate(59.413 59.413) rotate(180)" fill="none" stroke="currentColor" stroke-width="1.5">
              <ellipse cx="29.706" cy="29.706" rx="29.706" ry="29.706" stroke="none"/>
              <ellipse cx="29.706" cy="29.706" rx="28.956" ry="28.956" fill="none"/>
            </g>
            <g id="Group_642" data-name="Group 642" transform="translate(42.298 36.754) rotate(180)">
              <line id="Line_32" data-name="Line 32" x2="17.16" y2="12.591" transform="translate(25.181) rotate(90)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
              <line id="Line_33" data-name="Line 33" y1="12.591" x2="17.16" transform="translate(12.591) rotate(90)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
            </g>
          </g>
        </svg>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileSwiper',
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    };
  },
};
</script>
