<template>
  <validation-observer ref="form" v-slot="{ passes, invalid }" id="icon-project-form" tag="div">
    <div v-if="success" class="px-4 py-3" role="alert">
      <p class="font-bold">Thank You!</p>
      <p class="text-sm">{{ success }}</p>
    </div>
    <div v-if="error" class="text-red-500 px-4 py-3" role="alert">
      <p class="text-sm">{{ error }}</p>
    </div>
    <form v-if="!success" @submit.prevent="SendMessage">
      <validation-provider v-slot="{ errors }" name="Name" rules="required" class="w-full" tag="div">
        <label for="form-name" class="label required sr-only">Name</label>
        <input
          id="form-name"
          v-model="form.name"
          :class="{ error: errors[0] }"
          type="text"
          placeholder="Name"
        />
        <div v-show="errors" class="error">{{ errors[0] }}</div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Email" rules="required|email" tag="div">
        <label for="form-email" class="label required sr-only">Email address</label>
        <input
          id="form-email"
          v-model="form.email"
          :class="{ error: errors[0] }"
          type="text"
          class="form-input input"
          placeholder="Email address"
        />
        <div v-show="errors" class="error">{{ errors[0] }}</div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Project Outline" rules="required" tag="div">
        <label for="form-message" class="label required sr-only">Project Outline</label>
        <textarea
          id="form-message"
          v-model="form.message"
          :class="{ error: errors[0] }"
          class="form-textarea textarea"
          rows="4"
          placeholder="Project Outline..."
        ></textarea>
        <div v-show="errors" class="error">{{ errors[0] }}</div>
      </validation-provider>

      <div class="flex">
        <button type="button" :disabled="loading" class="button" @click="passes(SendMessage)">
          {{ !loading ? 'Send' : 'Sending... ' }}
        </button>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import axios from "axios";

export default {
  name: 'ProjectForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      form: {
        name: null,
        email: null,
        message: null,
      }
    }
  },
  methods: {
    SendMessage() {
      this.loading = true;
      let self = this;

      this.$refs.form.validate().then(function (valid) {
        if (valid) {
          axios.post('/wp-json/icon/v1/project', self.form)
            .then((response) => {
              // Clear input fields.
              self.form.email = null
              self.form.name = null;
              self.form.message = null;
              self.error = false;

              self.success = response.data.message;
            })
            .catch((err) => {
              self.success = false;
              self.error = err.response.data.message;
            })
            .then(() => {
              self.loading = false;
            })
        }
      });

    }
  }
}
</script>
